<template>
    <v-badge
        bordered
        color="light-blue lighten-3"
        icon="mdi-lock"
        overlap
        :value="!!wdRecord.freezed && !editable"
    >
        <v-card
            height="350"
            width="300"
            :color="absenceType == 'LEAVE' ? 'teal accent-3' : 'cyan accent-3'"
            rounded="lg"
            >
            <v-toolbar
                height="210"
                flat
            >
                <v-container fluid class="pt-12">
                <v-row class="mb-2">
                    <v-sheet
                    
                        width="100%"
                        rounded="xl"
                        :color="absenceType == 'LEAVE' ? 'teal accent-1' : 'cyan accent-1'"
                        class="d-flex align-center"
                    >
                        <div class="text-h6 d-flex justify-center flex-grow-1">
                            {{absenceType == 'LEAVE' ? 'Permesso' : 'Ferie'}}
                        </div>
                        <v-chip
                                v-if="absenceRecord.apply104"
                                class="p-0"
                                color="secondary"
                                >
                                104
                            </v-chip>
                    </v-sheet>
                </v-row>
                <v-row>
                    <v-sheet
                        height="165"
                        width="100%"
                        rounded="xl"
                        color="grey lighten-4"
                        class="d-flex justify-center align-center"
                    >
                        <div class="text-h3">
                            {{displayDuration}}
                           
                        </div>
                    </v-sheet>
                </v-row>
                </v-container>
            
                <template v-slot:extension>
                <v-btn
                    fab
                    right
                    absolute
                    bottom
                    style="z-index: 2;"
                    color="amber darken-2"
                    dark
                    @click="emitEditEvent"
                    v-if="editable && (absenceType == 'LEAVE' || (absenceType == 'HOLIDAY' && !absenceRecord.fullDay))"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    fab
                    right
                    absolute
                    bottom
                    style="z-index: 2;"
                    color="red lighten-1"
                    dark
                    @click="emitDeleteEvent"
                    v-if="editable && absenceType == 'HOLIDAY' && absenceRecord.fullDay"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                
                </template>
                
            </v-toolbar>
            <v-card-text class="pa-2">
                <v-container fluid v-if="showNotes">
                    <v-row no-gutters dense>
                        <v-col cols="7" class="d-flex align-start justify-start">
                            <div style="max-width: 200px; max-height: 50px; overflow: hidden;" class="text-wrap">
                                
                                {{wdRecord.note}}
                            </div>
                        </v-col>
                        <v-col cols="5" class="d-flex align-end justify-end">
                            <v-btn 
                                small 
                                rounded 
                                class="mt-8"
                                v-if="editable"
                                @click="emitEditNoteEvent"
                            >
                                <v-icon left small>
                                    mdi-text
                                </v-icon>
                                Note 
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-badge>
</template>
<script>
export default {
    data: function() {
        return {

        }
    },
    props: {
        wdRecord: {
            type: Object,
            required: true
        },
        // absenceRecord: {
        //     type: Object,
        //     required: true
        // },
        absenceType: {
            type: String,
            required: true,
            validator: val => ['LEAVE', 'HOLIDAY'].includes(val)
        },
        // readOnly: {
        //     type: Boolean,
        //     default: false
        // },
        editable: {
            type: Boolean,
            default: true
        },
        showNotes: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        displayDuration: function() {
            let hours = Math.floor(this.absenceRecord.minuteDuration / 60);
            let minutes = this.absenceRecord.minuteDuration % 60;
            let string = '';
            if(hours > 0)
                string += hours + 'h';
            if(minutes > 0)
                string += (' ' + minutes + 'm')
            return string.trim();
        },
        absenceRecord: function() {
            if (this.absenceType == 'LEAVE')
                return this.wdRecord.leaveRecord;
            else
                return this.wdRecord.holidayRecord;
        },
        // editable: function() {
        //     return !(this.wdRecord.freezed || this.readOnly);
        // }
    },
    methods: {
        emitEditEvent: function() {
            this.$emit('absence-edit')
        },
        emitDeleteEvent: function() {
            this.$emit('absence-delete');
        },
        emitEditNoteEvent: function() {
            this.$emit('edit-record-note');
        }
    }
}
</script>